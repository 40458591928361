var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useEffect, useRef, useState } from 'react';
const initialState = {
    error: undefined,
    data: undefined,
    isLoading: false,
    isError: false
};
export function useFetch(url, method = 'GET', _a = {}) {
    var { triggerOnMount = true } = _a, options = __rest(_a, ["triggerOnMount"]);
    const cancelRequest = useRef(false);
    const [state, setState] = useState(initialState);
    const runRequest = useCallback((body) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        // Do nothing if the url is not given
        if (!url)
            return;
        cancelRequest.current = false;
        setState(Object.assign(Object.assign({}, initialState), { isLoading: true }));
        try {
            const response = yield fetch(process.env.TRACKING_ENDPOINT + url, Object.assign(Object.assign({ method }, options), { headers: Object.assign(Object.assign({}, options.headers), { 'content-type': 'application/json' }), body: body && JSON.stringify(body) }));
            if (!response.ok) {
                const message = ((_a = response.headers
                    .get('content-type')) === null || _a === void 0 ? void 0 : _a.includes('application/json'))
                    ? (yield response.json()).message
                    : response.statusText;
                throw new Error(message);
            }
            const data = (yield response.json());
            if (data.status !== 'OK') {
                throw new Error(data.message);
            }
            if (cancelRequest.current)
                return;
            setState(Object.assign(Object.assign({}, initialState), { data: data.payload }));
        }
        catch (error) {
            if (cancelRequest.current)
                return;
            setState(Object.assign(Object.assign({}, initialState), { isError: true, error: error }));
        }
    }), [url, method]);
    const setData = useCallback(update => {
        setState(state => (Object.assign(Object.assign({}, state), { data: update(state.data) })));
    }, [setState]);
    useEffect(() => {
        if (method === 'GET' && triggerOnMount)
            runRequest();
        return () => {
            cancelRequest.current = true;
        };
    }, [runRequest, method, triggerOnMount]);
    return Object.assign(Object.assign({}, state), { runRequest: runRequest, setData });
}
