import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Paragraph } from '@gordondelivery/gordon-design-components/Typography';
import { PageContent } from '../../components/Page';
import { Section } from '../../components/Section';
import './NotFound.scss';
import { useDeviceWidth } from '@gordondelivery/gordon-design-components/utils/hooks';
import { Button } from '@gordondelivery/gordon-design-components/Button';
export const NotFound = ({ error }) => {
    const { t } = useTranslation();
    const { isMobile } = useDeviceWidth();
    const shouldShowAppInfo = error === 'Forbidden' || error === 'Order has expired';
    const handleClick = () => {
        window.location.href = 'https://grdn.io/get-app';
    };
    return (React.createElement("div", { className: "not-found" },
        React.createElement("div", { className: "not-found__body" },
            React.createElement(PageContent, { padding: true, background: true }, shouldShowAppInfo ? (React.createElement("img", { className: "not-found__body__img", src: "/img/app-illustration.svg", alt: "not found page illustration" })) : (React.createElement("img", { className: "not-found__body__img", src: "/img/person-relaxing.svg", alt: "not found page illustration" }))),
            React.createElement("div", { className: "not-found__content" },
                React.createElement(PageContent, { padding: true, background: true },
                    React.createElement(Section, { spacing: "medium" },
                        React.createElement(Heading, { size: "h5" }, t('notFoundPage.supportingText')),
                        shouldShowAppInfo ? (React.createElement(React.Fragment, null,
                            React.createElement(Heading, { size: "h1" }, t('notFoundPage.showAppInfo.header')),
                            React.createElement(Paragraph, { size: "large", color: "strong" }, t('notFoundPage.showAppInfo.description')),
                            isMobile ? (React.createElement("div", { className: 'button-navigation' },
                                React.createElement(Button, { size: "large", variant: "primary", text: t('notFoundPage.showAppInfo.buttonText'), onClick: handleClick }))) : (React.createElement("img", { className: "not-found-qr", height: 140, width: 140, src: "/img/getApp.svg", alt: "qr-code to download the app" })))) : (React.createElement(React.Fragment, null,
                            React.createElement(Heading, { size: "h1" }, t('notFoundPage.header')),
                            React.createElement(Paragraph, { size: "large", color: "strong" }, t('notFoundPage.description'))))))))));
};
