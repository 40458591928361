import { useMemo } from 'react';
export const useCustomTheme = (account) => {
    return useMemo(() => (account === null || account === void 0 ? void 0 : account.hasCustomTrackingTheme) && account.trackingTheme
        ? {
            '--top-section-background-color': account.trackingTheme.topSectionBackgroundColor,
            '--top-section-font-color': account.trackingTheme.topSectionFontColor,
            '--secondary-border-color': account.trackingTheme.secondaryBorderColor,
            '--secondary-font-color': account.trackingTheme.secondaryFontColor,
            '--backgrounds-interactive-brand-active': account.trackingTheme.primaryBackgroundColor,
            '--backgrounds-interactive-brand-default': account.trackingTheme.primaryBackgroundColor,
            '--backgrounds-interactive-brand-disabled': account.trackingTheme.primaryBackgroundColor,
            '--backgrounds-interactive-brand-hover': account.trackingTheme.primaryBackgroundColor,
            '--backgrounds-interactive-brand-subtle': account.trackingTheme.primaryBackgroundColor + '0A',
            '--text-link-active': account.trackingTheme.primaryBackgroundColor,
            '--text-link-hover': account.trackingTheme.primaryBackgroundColor,
            '--text-link-default': account.trackingTheme.primaryBackgroundColor,
            '--icons-brand': account.trackingTheme.primaryBackgroundColor,
            '--text-inverse': account.trackingTheme.primaryFontColor,
            '--icons-inverse': account.trackingTheme.primaryFontColor,
            '--borders-selected': account.trackingTheme.primaryBackgroundColor
        }
        : undefined, [account === null || account === void 0 ? void 0 : account.hasCustomTrackingTheme, account === null || account === void 0 ? void 0 : account.trackingTheme]);
};
