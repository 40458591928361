import React, { useEffect } from 'react';
import './Page.scss';
import { classNames } from '../../utils/helpers';
export const Page = ({ mapView = false, whiteBackground = false, children }) => {
    useEffect(() => {
        if (mapView) {
            // this will lock the scroll when the map is open on iOS
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);
    return (React.createElement("div", { className: classNames('TrackingPage', mapView && 'TrackingPage--map-view', whiteBackground && 'TrackingPage--white') }, children));
};
const PageWrapper = ({ spacing, children }) => {
    return (React.createElement("div", { className: classNames('PageWrapper', spacing === 'small'
            ? 'PageWrapper--spacing-small'
            : spacing === 'medium'
                ? 'PageWrapper--spacing-medium'
                : undefined) }, children));
};
export const PageHero = ({ children }) => {
    return (React.createElement("div", { className: "PageHero" },
        React.createElement(PageWrapper, null, children)));
};
export const PageBody = ({ children }) => {
    return React.createElement("div", { className: "PageBody" }, children);
};
export const PageContent = ({ background, children, spacing, padding = false }) => {
    return (React.createElement("div", { className: classNames('PageContent', background && 'PageContent--background', padding && 'PageContent--padding') },
        React.createElement(PageWrapper, { spacing: spacing }, children)));
};
